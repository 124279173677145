<!--
 * @Author: sopen
 * @Date: 2021-06-30 10:04:37
 * @LastEditTime: 2021-12-03 14:58:25
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /vue-uscenter/src/views/home/usinfo.vue
-->
<template>
  <v-app style="height: 600px">
    <inc-header></inc-header>
    <v-container grid-list-xs class="pt-10 grey lighten-4" style="max-width: 920px">
      <v-subheader v-if="goback.url != '' ? true : false" class="px-0 mb-4">
        <v-btn color="primary" tile @click="gotoUrl(goback.url)">
          返回 {{ goback.name }}
          <v-icon class="ml-2">mdi-link</v-icon>
        </v-btn>
      </v-subheader>
      <v-card flat tile v-if="usinfo.TARGETID" id="change">
        <v-card-title primary-title class="pb-0">
          {{ usinfo.NAME }}
          {{ usinfo.TYPE == "teacher" ? "老师" : "同学" }}
        </v-card-title>
        <v-card-text class="pt-0">
          {{ usinfo.SCHOOLNAME }}
        </v-card-text>
        <v-card-text>
          <v-btn
            tile
            dark
            elevation="1"
            color="orange darken-3"
            small
            @click="dialog = true"
            v-if="teacher.length + student.length + parents.length > 1"
          >
            <v-icon class="mr-2">mdi-swap-horizontal</v-icon>
            却换身份
          </v-btn>
        </v-card-text>
      </v-card>

      <v-card flat tile v-else>
        <v-card-title primary-title class="red--text"> 你还未关联用户身份 </v-card-title>
        <v-card-text>
          注意：关联用户身份才能进入学校社区，请在用户信息里完成身份操作
        </v-card-text>
        <v-card-text>
          <p>1、教师、学生请在“用户设置”的“用户信息”完成关联</p>
          <p>2、家长请在“用户设置”的“监护人”完成关联</p>
        </v-card-text>
      </v-card>
      <v-divider></v-divider>
      <v-card flat tile>
        <v-card-text class="px-2">
          <v-row>
            <v-col cols="4" md="3" v-for="(item, x) in items" :key="x">
              <v-card flat :id="item.id">
                <v-card-text class="text-center pb-2">
                  <v-btn
                    :color="item.color"
                    elevation="1"
                    fab
                    dark
                    @click="$router.push({ path: item.path })"
                    large
                  >
                    <v-icon large>{{ item.icon }}</v-icon>
                  </v-btn>
                </v-card-text>
                <v-card-text class="text-center subtitle-1 font-weight-bold py-0">
                  {{ item.title }}
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-dialog
        v-model="dialog"
        scrollable
        :fullscreen="mobile"
        persistent
        :overlay="false"
        max-width="500px"
        transition="dialog-transition"
      >
        <v-card>
          <v-card-title primary-title class="pr-2">
            切换身份
            <v-spacer></v-spacer>
            <v-btn icon @click="dialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text class="py-4">
            <v-row>
              <v-col cols="12" v-if="Object.keys(teacher).length > 0">
                <v-card
                  tile
                  outlined
                  v-for="(item, x) in teacher"
                  :key="x"
                  @click="reusrec('T' + item.id)"
                >
                  <v-card-title primary-title class="pb-0">
                    {{ item.name }}老师
                  </v-card-title>
                  <v-card-text class="pt-0">
                    {{ school[item.school].name }}
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12" v-if="Object.keys(student).length > 0">
                <v-card
                  tile
                  outlined
                  v-for="(item, y) in student"
                  @click="reusrec('S' + item.id)"
                  :key="y"
                >
                  <v-card-title class="text-h8" primary-title>
                    {{ item.name }}同学
                  </v-card-title>
                  <v-card-text class="pt-0">
                    {{ school[item.school].name }}
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12" v-if="parents.length > 0">
                <v-card
                  tile
                  outlined
                  v-for="(item, x) in parents"
                  :key="x"
                  @click="reusrec('S' + item.id)"
                >
                  <v-card-title class="text-h8" primary-title>
                    {{ item.name }}的家长
                  </v-card-title>
                  <v-card-text class="pt-0">
                    {{ school[item.school].name }}
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-container>
  </v-app>
</template>
<script>
import incHeader from "../inc-header.vue";
import Driver from "driver.js";
import "driver.js/dist/driver.min.css";
export default {
  components: {
    incHeader,
  },
  props: {
    mobile: Boolean,
  },
  data() {
    return {
      usinfo: {},
      teacher: [],
      student: [],
      parents: [],
      openuid: "",
      school: null,
      dialog: false,
      items: [
        {
          id: "user",
          title: "用户信息",
          color: "success darken-1",
          subtitle: `<span class="text--primary">Ali Connors</span>`,
          icon: "mdi-card-account-details-outline",
          path: "/user/usinfo",
        },
        {
          id: "passwd",
          title: "密码修改",
          color: "deep-purple darken-1",
          subtitle: `<span class="text--primary">Ali Connors</span>`,
          icon: "mdi-key-variant",
          path: "/user/passwd",
        },
        {
          id: "mobile",
          title: "手机号码",
          color: "blue-grey darken-1",
          subtitle: `<span class="text--primary">Ali Connors</span>`,
          icon: "mdi-cellphone",
          path: "/user/phone",
        },
        {
          id: "parent",
          title: "监护人",
          color: "blue darken-1",
          subtitle: `<span class="text--primary">Ali Connors</span>`,
          icon: "mdi-human-male-boy",
          path: "/user/parent",
        },
      ],
      goback: {},
    };
  },
  created() {},
  mounted() {
    this.reusrec();
    let goback = localStorage.getItem("goback");
    if (goback != undefined) this.goback = JSON.parse(goback);
    setTimeout(() => {
      this.loadHelp();
      if (this.$route.query.goback) {
        localStorage.setItem("goback", this.$route.query.goback);
        this.goback = JSON.parse(this.$route.query.goback);
      }
    }, 500);
  },
  methods: {
    help() {
      const driver = new Driver({
        doneBtnText: "完成", // Text on the final button
        closeBtnText: "关闭", // Text on the close button for this step
        stageBackground: "grey", // Background color for the staged behind highlighted element
        allowClose: false,
        nextBtnText: "下一步", // Next button text for this step
        prevBtnText: "上一步", // Previous button text for this step
      });
      driver.defineSteps([
        {
          element: "#home-menu",
          popover: {
            title: "<h6>这是主菜单</em>",
            description: "左边按钮可以弹出左侧菜单列表，右边按钮可以提供页面帮助",
          },
        },
        {
          element: "#hmenu",
          popover: {
            title: "<h6>这是主菜单</em>",
            description: "左边按钮可以弹出左侧菜单列表，右边按钮可以提供页面帮助",
          },
        },
        {
          element: "#change",
          popover: {
            title: "当用户为多个身份时，可以切换",
            description: "监护人可以增设多个子女，并在这里切换不同的子女身份。",
          },
        },
        {
          element: "#user",
          popover: {
            title: "用户真实信息",
            description:
              "用户本人的身份信息，教师及学生在这里设定自己信息并关联自己的学校。",
          },
        },
        {
          element: "#parent",
          popover: {
            title: "添加自己的监护信息",
            description:
              "家长或者教师身份的家长均可在这里增加监护信息（子女），添加成功后，可关联孩子的学校教学服务。",
          },
        },
        {
          element: "#help",
          popover: {
            title: "帮助按钮",
            description: "这里是页面帮助指引",
            position: "bottom-right",
          },
        },
      ]);
      driver.start();
    },
    loadHelp() {
      let that = this;
      this.$sopen.getHelp(220215).then(function (res) {
        if (res.errmsg == "none") {
          that.help();
        }
      });
    },
    reusrec(targetid = "") {
      let tokenValue = this.$sopen.getCookie();
      if (!targetid) {
        let t = this.$sopen.getCookie("uskey");
        targetid = t.TARGETID;
      }
      this.openuid = tokenValue.urec;
      let data = {
        module: "usinfo",
        title: "reusrec",
        data: {
          cookie: JSON.parse(tokenValue),
          targetid: targetid,
          url:
            document.location.protocol +
            "//" +
            (window.location.host.indexOf("schoolopen") > -1
              ? window.location.host
              : "luohu.sopen.cn"),
        },
      };
      let that = this;
      this.$sopen.requestUscenterApi(data).then(function (res) {
        that.usinfo = res.data.usinfo.usinfo;
        if (that.usinfo.INFO.teacher && that.usinfo.INFO.teacher.data) {
          for (let x in that.usinfo.INFO.teacher.data) {
            that.teacher.push(that.usinfo.INFO.teacher.data[x]);
          }
        }
        if (that.usinfo.INFO.student && that.usinfo.INFO.student.data) {
          for (let x in that.usinfo.INFO.student.data) {
            that.student.push(that.usinfo.INFO.student.data[x]);
          }
        }
        if (that.usinfo.INFO.parents && that.usinfo.INFO.parents.data) {
          for (let x in that.usinfo.INFO.parents.data) {
            that.parents.push(that.usinfo.INFO.parents.data[x]);
          }
        }
        if (that.usinfo.INFO.school && that.usinfo.INFO.school.data) {
          that.school = that.usinfo.INFO.school.data;
        }
        if (targetid != "") {
          ////console.log("/token?openuid=" + res.data.usinfo.openuid);
          /*
          that.$router.push({
            path: "/token?openuid=" + res.data.usinfo.openuid,
          });
          //*/
          that.dialog = false;
          that.target(res.data.usinfo.openuid);
        }
      });
    },
    target(openuid) {
      let data = {
        module: "user_info",
        title: "setcookie",
        res: openuid,
      };
      //console.log(data);
      this.$sopen.requestApi(data).then(function (res) {
        sessionStorage.setItem("uskey", JSON.stringify(res.errmsg));
        //window.location.reload();
        /*
        that.$router.push({
          path: "/home/",
        });
        //*/
      });
    },
    gotoUrl(url) {
      console.log(url);
      window.location.href = url;
    },
  },
};
</script>
